<div class="iternary-wrapper">
  <div id="map" class="iternary-map"></div>
  <div class="itinerary-content" (scroll)="onScroll($event)">
    <section
      *ngFor="let city of itineraryData"
      [id]="city.name"
      class="itinerary-section"
    >
      <div
        class="location"
        [ngClass]="{
          'active-location': activeCity === city.name,
          'other-location': activeCity !== city.name
        }"
      >
        <img
          src="assets/icons/location.svg"
          alt="Location Icon"
          class="location-icon"
        />
        {{ city.name }}
      </div>

      <div *ngFor="let detail of city.details">
        <h3>{{ detail.day }}</h3>
        <p>{{ detail.description }}</p>
        <strong>{{ detail.hotel }}</strong> | <em>{{ detail.meals }}</em>
      </div>
    </section>
  </div>
</div>

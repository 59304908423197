<section>
  <div class="dropdown-container">
    <label for="menu">More</label>
    <select id="menu" [(ngModel)]="selectedOption">
      <option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
  <!-- Dynamic Content Changes -->
  <section class="trending-adventure">
    <div class="travel-destinations">
      <div *ngIf="selectedOption === 'option1'">
        <owl-carousel-o [options]="customOptions" class="carousel-container">
          <ng-container *ngFor="let item of carouselImages1">
            <ng-template carouselSlide>
              <div class="slide">
                <img
                  [src]="item.image"
                  alt="{{ item.title }}"
                  class="slide-image"
                />
                <div class="description">
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                  <p>{{ item.price }}</p>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <div *ngIf="selectedOption === 'option2'">
        <owl-carousel-o [options]="customOptions" class="carousel-container">
          <ng-container *ngFor="let item of carouselImages2">
            <ng-template carouselSlide>
              <div class="slide">
                <img
                  [src]="item.image"
                  alt="{{ item.title }}"
                  class="slide-image"
                />
                <div class="description">
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                  <p>{{ item.price }}</p>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <div *ngIf="selectedOption === 'option3'">
        <owl-carousel-o [options]="customOptions" class="carousel-container">
          <ng-container *ngFor="let item of carouselImages3">
            <ng-template carouselSlide>
              <div class="slide">
                <img
                  [src]="item.image"
                  alt="{{ item.title }}"
                  class="slide-image"
                />
                <div class="description">
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                  <p>{{ item.price }}</p>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <div *ngIf="selectedOption === 'option4'">
        <owl-carousel-o [options]="customOptions" class="carousel-container">
          <ng-container *ngFor="let item of carouselImages4">
            <ng-template carouselSlide>
              <div class="slide">
                <img
                  [src]="item.image"
                  alt="{{ item.title }}"
                  class="slide-image"
                />
                <div class="description">
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                  <p>{{ item.price }}</p>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </section>
</section>

<section>
  <div id="imgtxtcontainer">
    <div id="txtpart">
      <div class="button-and-subheader">
        <p>WHERE YOU WILL STAY</p>
      </div>
      <h4>Hand Selected for an Unmatched Experience</h4>
    </div>
  </div>
</section>

<section class="trending-adventure">
  <div class="travel-destinations">
    <owl-carousel-o [options]="customOptions" class="carousel-container">
      <ng-container *ngFor="let item of carouselImages">
        <ng-template carouselSlide>
          <div class="slide">
            <img
              [src]="item.image"
              alt="{{ item.title }}"
              class="slide-image"
            />
            <div class="description">
              <h1>{{ item.title }}</h1>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</section>

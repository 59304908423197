<header class="header-container">
  <h1>Chaviti</h1>
</header>

<div class="header-spacer"></div>

<nav id="anchors-menu" class="sticky-bar">
  <!-- <button
    id="backToTop"
    class="back-to-top"
    (click)="scrollTo('top')"
    [class.show]="showBackToTop"
  >
    Back to Top
  </button> -->
  <a *ngFor="let link of links" (click)="scrollTo(link.id, $event)">{{
    link.name
  }}</a>
</nav>

<div class="nav-spacer"></div>

<section class="journey-intro">
  <img
    src="assets/images/small-group-journey-bg.webp"
    alt="Spain & Portugal Journey"
    class="journey-image"
  />
  <div class="image-overlay">
    <h2>Spain & Portugal: A Journey Across Iberia 2025</h2>
  </div>
</section>

<section id="overview" class="section-container">
  <h2>Overview</h2>
  <app-overview></app-overview>
</section>
<section id="itinerary" class="section-container-iternary">
  <h2>Itinerary</h2>
  <app-itinerary></app-itinerary>
</section>
<section id="stays" class="section-container">
  <h2>Stays</h2>
  <app-where-to-stay></app-where-to-stay>
</section>
<section id="dates-prices" class="section-container">
  <app-date-price></app-date-price>
</section>
<section id="journey-details" class="section-container">
  <h2>Journey Details</h2>
  <app-more-adventures></app-more-adventures>
</section>
<section class="join-now-container">
  <button mat-flat-button (click)="openModal()">Join Now</button>
</section>

import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
})
export class TourComponent {
  hoveredIndex: number | null = null;
  walletCards = [
    {
      title: 'Travel Your Way',
      imageUrl:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/961cb889752cc9f3be22533c1a2463e33852dfd3-2048x2560.jpg',
      description:
        'For six decades we have been refining the art of bespoke travel, showing every guest the world in a fresh light...',
      link: '/all-journeys/private-travel',
      buttonText: 'FIND YOUR ADVENTURE',
    },
    {
      title: 'With You All The Way',
      imageUrl:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/2f2c73cb34a30abbc46f15260e808c4f48f5cf86-2048x2560.jpg',
      description:
        'Behind every A&K journey is an international support system of more than 2,500 staff, in 60 offices, in 35 countries...',
      link: '/all-journeys/',
      buttonText: 'START EXPLORING',
    },
    {
      title: 'Travel Thoughtfully',
      imageUrl:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/70932a47c5cc8f9c64b593cf498af5e5b2fa9537-2048x2560.jpg',
      description:
        'We are all the guardians of our amazing planet, and we believe travel should be considered, mindful, responsible...',
      link: '/philanthropy',
      buttonText: 'GET INVOLVED',
    },
    {
      title: 'The A&K Family',
      imageUrl:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/7dd493bcf80bf734aad196f90c67ab26de6917ae-5138x6423.jpg',
      description:
        'For all the world’s infinite wonders, it is our people that guests remember most. Our family of experts...',
      link: '/about-us',
      buttonText: 'WORK WITH US',
    },
  ];
  itemsPerSlide: number = 1;
  screenWidth: number = window.innerWidth;
  customOptions = {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  carouselImages = [
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description:
        'The Taj Mahal at dawn, a Bengal tiger resting amid teak forest shadows, the sensory cavalcade of a Mumbai marketplace — this is India revealed only as A&K can.',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'A Thrilling Adventure in the Kenyan Wilderness',
      description:
        'Starting in vibrant Nairobi, the only city in the world where you can safari to a backdrop of skyscrapers, this Tailormade Journey prioritises the best that Kenya has to offer.',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/eb9e14a36021e7cab8cae231466a03750a78d41d-3612x3612.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Explore Antarctica, South Georgia and the Falkland Islands',
      description:
        "Take the polar plunge and cruise south with us on our latest Antarctica cruise, where you'll be joined by an Expedition Team that brings the history of polar exploration to life.",
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Discover the Beauty of Iceland',
      description:
        'From geysers to glaciers, explore the breathtaking landscapes of Iceland with this once-in-a-lifetime adventure.',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Experience the Wonders of Australia',
      description:
        'Dive into the Great Barrier Reef, explore the Outback, and enjoy the vibrant cities of Australia on this unforgettable journey.',
    },
  ];

  destinationImg =
    'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format';
  journeysImg =
    'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format';
  staysImg =
    'https://cdn.abercrombiekent.com/images/bsiop5ln/production/eb9e14a36021e7cab8cae231466a03750a78d41d-3612x3612.jpg?w=3840&q=75&fit=max&auto=format';
  imageSrc =
    'https://cdn.abercrombiekent.com/images/bsiop5ln/production/5d31e4404cd090e18f5d9ab9e160a63c01fff73a-2233x1489.jpg?w=3840&q=75&fit=max&auto=format';
  smallJourneyImg =
    'https://cdn.abercrombiekent.com/images/bsiop5ln/production/643d27da64131f0d4858f6d30e16f523997b965c-5464x7286.jpg?w=3840&q=75&fit=max&auto=format';

  trips = [
    {
      title: 'Wilderness',
      imageUrl: 'assets/background-image.jpg',
      link: '/wilderness',
      class: 'mosaic-item-0',
    },
    {
      title: 'Wildlife & Safari',
      imageUrl: 'assets/birdinfo.jpg',
      link: '/safari',
      class: 'mosaic-item-1',
    },
    {
      title: 'Adventure',
      imageUrl: 'assets/car.jpg',
      link: '/adventure',
      class: 'mosaic-item-2',
    },
    {
      title: 'Culture & History',
      imageUrl: 'assets/circus.jpg',
      link: '/culture',
      class: 'mosaic-item-3',
    },
    {
      title: 'Family',
      imageUrl: 'assets/postcard.jpg',
      link: '/family',
      class: 'mosaic-item-4',
    },
    {
      title: 'Solo',
      imageUrl: 'assets/spices.jpg',
      link: '/solo',
      class: 'mosaic-item-5',
    },
  ];

  constructor() {
    this.updateItemsPerSlide();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.screenWidth = (event.target as Window).innerWidth;
    this.updateItemsPerSlide();
  }

  updateItemsPerSlide() {
    this.itemsPerSlide = this.screenWidth > 768 ? 3 : 1; // 3 for large screens, 1 for mobile
  }

  // Group images into chunks based on items per slide
  getCarouselItems() {
    const groups = [];
    for (let i = 0; i < this.carouselImages.length; i += this.itemsPerSlide) {
      groups.push(this.carouselImages.slice(i, i + this.itemsPerSlide));
    }
    return groups;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-date-price',
  templateUrl: './date-price.component.html',
  styleUrls: ['./date-price.component.scss'],
})
export class DatePriceComponent {
  benefitsList: string[] = [
    'English-Speaking Resident Tour Director® and Local Guides',
    'Airport Meet and Greet with Private Transfers',
    'Travelling Bell Boy® Luggage Handling',
    'Traveller’s Valet® Laundry Service',
    'Internet Access',
    'Entrance Fees, Taxes and All Gratuities Except Resident Tour Director',
    '24/7 A&K On-Call Support',
    'Internal Air Included (Economy Class, $300 value) Bilbao/Barcelona',
  ];

  trips = [
    {
      date: 'Apr 18 - May 1',
      status: 'Call For Availability',
      price: '$14,795',
      supplement: '$4,295',
      details: 'Optional pre-tour extension available on this departure.',
      expanded: false,
    },
    {
      date: 'May 2 - May 15',
      status: 'Available',
      price: '$13,995',
      supplement: '$4,000',
      details: 'Includes guided tours.',
      expanded: false,
    },
    {
      date: 'May 16 - May 29',
      status: 'Waitlist Only - Call A&K',
      price: '$14,500',
      supplement: '$4,200',
      details: 'Limited spots remaining.',
      expanded: false,
    },
    {
      date: 'May 23 - Jun 5',
      status: 'Limited Availability',
      price: '$14,100',
      supplement: '$4,100',
      details: 'Book soon before spots run out.',
      expanded: false,
    },
    {
      date: 'Jun 13 - Jun 26',
      status: 'Limited Availability',
      price: '$14,200',
      supplement: '$4,250',
      details: 'Book soon before spots run out.',
      expanded: false,
    },
    {
      date: 'Jun 27 - Jul 10',
      status: 'Call For Availability',
      price: '$14,300',
      supplement: '$4,300',
      details: 'Check availability with A&K.',
      expanded: false,
    },
    {
      date: 'Jul 11 - Jul 24',
      status: 'Available',
      price: '$14,400',
      supplement: '$4,350',
      details: 'Guided tours included.',
      expanded: false,
    },
    {
      date: 'Sep 5 - Sep 18',
      status: 'Waitlist Only - Call A&K',
      price: '$14,500',
      supplement: '$4,400',
      details: 'Join the waitlist.',
      expanded: false,
    },
    {
      date: 'Sep 12 - Sep 25',
      status: 'Call For Availability',
      price: '$14,600',
      supplement: '$4,450',
      details: 'Limited spots available.',
      expanded: false,
    },
    {
      date: 'Sep 19 - Oct 2',
      status: 'Available',
      price: '$14,700',
      supplement: '$4,500',
      details: 'Book now.',
      expanded: false,
    },
    {
      date: 'Sep 26 - Oct 9',
      status: 'Call For Availability',
      price: '$14,800',
      supplement: '$4,550',
      details: 'Limited spots.',
      expanded: false,
    },
    {
      date: 'Oct 10 - Oct 23',
      status: 'Waitlist Only - Call A&K',
      price: '$14,900',
      supplement: '$4,600',
      details: 'Join the waitlist.',
      expanded: false,
    },
  ];

  closeOtherTrips(selectedTrip: any) {
    this.trips.forEach((trip) => {
      if (trip !== selectedTrip) {
        trip.expanded = false;
      }
    });
  }
}

<div class="video-container">
  <video autoplay muted loop class="background-video">
    <source
      src="https://cdn.abercrombiekent.com/files/bsiop5ln/production/5b783d00300c87f2ae7550551195a54378d361e3.mov"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
  <div class="text-overlay">
    <h1>
      Since 1962 we have set the standard for experiential travel. We believe
      that every journey should be extraordinary; every life, well-travelled.
    </h1>
  </div>
</div>

<section
  class="background-silk-texture-canvas mx-auto flex w-full flex-col items-center justify-center"
>
  <div class="constrain-container">
    <div class="cards-grid">
      <!-- Destination Card -->
      <a href="/destinations">
        <div class="highlighted-card-destinations-container cursor-pointer">
          <img
            alt="img"
            class="card-image"
            [src]="destinationImg"
            loading="lazy"
            width="1440"
            height="1440"
          />
          <div class="gradient-cards-overlay"></div>
          <div class="highlighted-card-destinations-container-text">
            <h4>Destinations</h4>
            <p>
              We can arrange remarkable travel experiences on all seven
              continents. Where do you want to go?
            </p>
          </div>
        </div>
      </a>

      <!-- Journeys Card -->
      <a href="/all-journeys">
        <div class="highlighted-card-destinations-container cursor-pointer">
          <img
            alt="All journeys"
            class="card-image"
            [src]="journeysImg"
            loading="lazy"
            width="1440"
            height="1440"
          />
          <div class="gradient-cards-overlay"></div>
          <div class="highlighted-card-destinations-container-text">
            <h4>Journeys</h4>
            <p>
              Find your dream journey, whether you want to travel privately or
              with a group, by train, boat or plane.
            </p>
          </div>
        </div>
      </a>

      <!-- Stays Card -->
      <a href="/stays">
        <div class="highlighted-card-destinations-container cursor-pointer">
          <img
            alt="img"
            class="card-image"
            [src]="staysImg"
            loading="lazy"
            width="1440"
            height="1440"
          />
          <div class="gradient-cards-overlay"></div>
          <div class="highlighted-card-destinations-container-text">
            <h4>Stays</h4>
            <p>
              Our collection of inspiring places to stay includes safari lodges,
              super-villas, and boutique riverboats.
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</section>

<section>
  <div id="imgtxtcontainer" class="bg-linen home-highlighted-section-title">
    <div id="txtpart">
      <div class="button-and-subheader">
        <strong>JOURNEYS</strong>
      </div>
      <h3>How do you want to travel?</h3>
    </div>
  </div>
</section>

<section class="home-splitted-card mx-auto bg-linen my-0 py-0">
  <div class="travel constrain-container" style="border-color: transparent">
    <!-- Left Section: Text Content -->
    <div id="split-view-internal-container">
      <div class="text-content">
        <div class="button-and-subheader">TRAVEL PRIVATELY</div>
        <h5>Adventures Custom-Made For You</h5>
        <p>
          Every A&K private journey is unique. Whether you want to personalize
          one of our expert-designed Tailormade Journeys, or an adventure that’s
          completely bespoke, we will craft the trip of your dreams, exclusively
          for you.
        </p>
        <p class="link-text">
          <a href=""><strong>DISCOVER MORE</strong></a>
        </p>
      </div>
    </div>

    <!-- Right Section: Image -->
    <div class="image-container">
      <img
        alt="photo"
        class="w-full"
        [src]="imageSrc"
        loading="lazy"
        width="1440"
        height="960"
      />
    </div>
  </div>
</section>

<section class="home-splitted-card">
  <div class="travel-destinations">
    <div class="grid-img-text">
      <div class="flex-img-text">
        <img
          alt="img"
          class="card-image"
          [src]="smallJourneyImg"
          loading="lazy"
          width="1440"
          height="1440"
        />
        <div class="text-container">
          <h5>Adventures Custom-Made For You</h5>
          <p>
            Every A&K private journey is unique. Whether you want to personalize
            one of our expert-designed Tailormade Journeys, or an adventure
            that’s completely bespoke, we will craft the trip of your dreams,
            exclusively for you.
          </p>
          <p class="link-text">
            <a href=""><strong>DISCOVER MORE</strong></a>
          </p>
        </div>
      </div>
      <div class="flex-img-text">
        <img
          alt="img"
          class="card-image"
          [src]="smallJourneyImg"
          loading="lazy"
          width="1440"
          height="1440"
        />
        <div class="text-container">
          <h5>Adventures Custom-Made For You</h5>
          <p>
            Every A&K private journey is unique. Whether you want to personalize
            one of our expert-designed Tailormade Journeys, or an adventure
            that’s completely bespoke, we will craft the trip of your dreams,
            exclusively for you.
          </p>
          <p class="link-text">
            <a href=""><strong>DISCOVER MORE</strong></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="home-splitted-card">
  <div class="travel-destinations">
    <div class="grid-img-text-reverse">
      <div class="flex-img-text">
        <img
          alt="img"
          class="card-image"
          [src]="smallJourneyImg"
          loading="lazy"
          width="1440"
          height="1440"
        />
        <div class="text-container">
          <h5>Adventures Custom-Made For You</h5>
          <p>
            Every A&K private journey is unique. Whether you want to personalize
            one of our expert-designed Tailormade Journeys, or an adventure
            that’s completely bespoke, we will craft the trip of your dreams,
            exclusively for you.
          </p>
          <p class="link-text">
            <a href=""><strong>DISCOVER MORE</strong></a>
          </p>
        </div>
      </div>
      <div class="flex-img-text">
        <img
          alt="img"
          class="card-image"
          [src]="smallJourneyImg"
          loading="lazy"
          width="1440"
          height="1440"
        />
        <div class="text-container">
          <h5>Adventures Custom-Made For You</h5>
          <p>
            Every A&K private journey is unique. Whether you want to personalize
            one of our expert-designed Tailormade Journeys, or an adventure
            that’s completely bespoke, we will craft the trip of your dreams,
            exclusively for you.
          </p>
          <p class="link-text">
            <a href=""><strong>DISCOVER MORE</strong></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div id="imgtxtcontainer" class="bg-linen home-highlighted-section-title">
    <div id="txtpart">
      <div class="button-and-subheader">
        <strong>TRENDING</strong>
      </div>
      <h3>New Adventures to Get Excited About</h3>
    </div>
  </div>
</section>

<section class="trending-adventure">
  <div class="travel-destinations">
    <div class="cards-grid">
      <!-- Destination Card -->
      <a href="/destinations">
        <div class="highlighted-card-destinations-container cursor-pointer">
          <img
            alt="img"
            class="card-image"
            [src]="destinationImg"
            loading="lazy"
            width="1440"
            height="1440"
          />
          <div class="gradient-cards-overlay"></div>
          <div class="highlighted-card-destinations-container-text">
            <h4>Uncover the Secrets of India</h4>
            <p>
              The Taj Mahal at dawn, a Bengal tiger resting amid teak forest
              shadows, the sensory cavalcade of a Mumbai marketplace — this is
              India revealed only as A&K can.
            </p>
          </div>
        </div>
      </a>

      <!-- Journeys Card -->
      <a href="/all-journeys">
        <div class="highlighted-card-destinations-container cursor-pointer">
          <img
            alt="All journeys"
            class="card-image"
            [src]="journeysImg"
            loading="lazy"
            width="1440"
            height="1440"
          />
          <div class="gradient-cards-overlay"></div>
          <div class="highlighted-card-destinations-container-text">
            <h4>A Thrilling Adventure in the Kenyan Wilderness</h4>
            <p>
              Starting in vibrant Nairobi, the only city in the world where you
              can safari to a backdrop of skyscrapers, this Tailormade Journey
              prioritises the best that Kenya has to offer.
            </p>
          </div>
        </div>
      </a>

      <!-- Stays Card -->
      <a href="/stays">
        <div class="highlighted-card-destinations-container cursor-pointer">
          <img
            alt="img"
            class="card-image"
            [src]="staysImg"
            loading="lazy"
            width="1440"
            height="1440"
          />
          <div class="gradient-cards-overlay"></div>
          <div class="highlighted-card-destinations-container-text">
            <h4>Explore Antarctica, South Georgia and the Falkland Islands</h4>
            <p>
              Take the polar plunge and cruise south with us on our latest
              Antarctica cruise, where you'll be joined by an Expedition Team
              that brings the history of polar exploration to life.
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</section>

<section
  class="mosaic-row-with-carousel-container background-silk-texture-canvas"
>
  <div class="mosaic-head">What kind of trip are you looking for?</div>
  <div class="mosaic-row-contents">
    <div class="mantine-Carousel-root">
      <div class="mantine-Carousel-viewport">
        <div
          class="mantine-Carousel-container"
          style="transform: translate3d(0px, 0px, 0px)"
        >
          <div class="mantine-Carousel-slide" *ngFor="let image of trips">
            <div [class]="image.class">
              <img [src]="image.imageUrl" [alt]="image.title" />
              <div class="carousel-row-inner-text">
                <h5>
                  <a href="#">{{ image.title }}</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="mantine-Carousel-slide" *ngFor="let image of trips">
            <div [class]="image.class">
              <img [src]="image.imageUrl" [alt]="image.title" />
              <div class="carousel-row-inner-text">
                <h5>
                  <a href="#">{{ image.title }}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mantine-Carousel-controls">
        <button class="mantine-UnstyledButton-root" type="button" tabindex="0">
          <div class="carousal-button1">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="12"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
              ></path>
            </svg>
          </div>
        </button>
        <button class="mantine-UnstyledButton-root" type="button" tabindex="0">
          <div class="carousal-button2">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="12"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
              ></path>
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
</section>

<section>
  <div id="imgtxtcontainer" class="bg-linen home-highlighted-section-title">
    <div id="txtpart">
      <div class="button-and-subheader">
        <strong>STAY WITH US </strong>
      </div>
      <h3>Discover our remarkable lodges, villas and riverboats</h3>
    </div>
  </div>
</section>
<section class="trending-adventure">
  <div class="travel-destinations">
    <owl-carousel-o [options]="customOptions" class="carousel-container">
      <ng-container *ngFor="let item of carouselImages">
        <ng-template carouselSlide>
          <div class="slide">
            <img
              [src]="item.image"
              alt="{{ item.title }}"
              class="slide-image"
            />
            <div class="description">
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</section>

<section class="parallax-video-effect-home-page">
  <div class="video-wrapper">
    <div class="overlay"></div>
    <video autoplay loop playsinline class="parallax-video" poster="">
      <source
        src="https://cdn.abercrombiekent.com/files/bsiop5ln/production/24f7267ef94856e0b11f66f22f0d958db6a5e829.mov"
        type="video/mp4"
      />
    </video>
    <div class="content-wrapper">
      <h2>
        "We are not in the travel business. <br />We are in the business of
        dreams."
      </h2>
      <div class="button-subheader">GEOFFREY KENT</div>
    </div>
  </div>
  <div class="extra-content"></div>
</section>

<section>
  <div id="imgtxtcontainer" class="bg-linen home-highlighted-section-title">
    <div id="txtpart">
      <h3>Why Travel with Abercrombie & Kent?</h3>
    </div>
  </div>
</section>
<section class="wallet-card-cont flexCont">
  <div class="content-cont">
    <div class="wallet-card-images-container" fxLayout="row" fxLayoutWrap>
      <div
        class="card"
        *ngFor="let card of walletCards; let i = index"
        [ngClass]="{
          active: hoveredIndex === i,
          inactive: hoveredIndex !== null && hoveredIndex !== i
        }"
        (mouseenter)="hoveredIndex = i"
        (mouseleave)="hoveredIndex = null"
      >
        <div class="card-image-wrapper">
          <div class="wallet-card-title-inner-text">
            <h5>{{ card.title }}</h5>
          </div>
          <img [src]="card.imageUrl" [alt]="card.title" class="card-image" />
        </div>
        <div class="wallet-card-content">
          <p>{{ card.description }}</p>
          <a [href]="card.link" class="btn-link"
            ><strong>{{ card.buttonText }}</strong></a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<section class="stays-philanthropy-container mat-elevation-z2">
  <div class="info-container">
    <div class="video-container">
      <video
        autoplay
        muted
        loop
        style="width: 100%; height: 100%; object-fit: cover"
      >
        <source
          src="https://cdn.abercrombiekent.com/files/bsiop5ln/production/bf8abe3ec1b71c32d3310d070755321c1d3553dd.mov"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="text-container">
      <h4>Travel Responsibly</h4>
      <p>
        We believe travel should be a force for good. In the 1960s, our founder
        Geoffrey Kent and Jorie Butler Kent pioneered the community-first
        approach to conservation. Today, we still believe that the best way to
        protect our planet’s remaining wilderness is to invest in the
        communities who live there.
      </p>
      <p>
        Every trip you book with us benefits grassroots community and
        conservation initiatives led by our nonprofit arm, A&amp;K Philanthropy
        (AKP). We now support 55 projects in 25 countries, helping preserve some
        of the world’s most precious ecosystems, and ensuring that the people in
        the places where we travel are benefitting directly from tourism.
      </p>
      <!-- <p>
        <a href="/philanthropy" mat-raised-button
          ><strong>FIND OUT MORE</strong></a
        >
      </p> -->
    </div>
  </div>
</section>

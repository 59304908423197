import { Component } from '@angular/core';

@Component({
  selector: 'app-selection-page',
  templateUrl: './selection-page.component.html',
  styleUrls: ['./selection-page.component.scss'],
})
export class SelectionPageComponent {
  selectedAdults: number | null = null;
  selectedMinors: number | null = null;

  selectAdults(number: number) {
    this.selectedAdults = number;
  }

  selectMinors(number: number) {
    this.selectedMinors = number;
  }

  submitSelection() {
    console.log('Selected Adults:', this.selectedAdults);
    console.log('Selected Minors:', this.selectedMinors);
  }

  reserve(): void {
    if (this.selectedAdults && this.selectedMinors) {
      alert(
        `Reservation confirmed: Adults - ${this.selectedAdults}, Minors - ${this.selectedMinors}`
      );
    } else {
      alert('Please select both Adults and Minors before reserving.');
    }
  }
}

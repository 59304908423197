<div class="mobile-view-header">
  <div class="website-landing-page-mobile">
    <div class="contact-us-section-mobile-content">
      <div class="landing-page-content">
        <h1 class="main-section-content">
          Masterfully crafted, exclusive, and<br />authentic experiences
          mirrored in you.
        </h1>
      </div>
    </div>
    <div class="contact-us-section-mobile-image">
      <img
        src="/assets/landing_screen.jpg"
        alt="Image"
        class="image-landing-screen"
      />
      <div class="box-behind"></div>
    </div>
    <div class="contact-us-section-mobile-content">
      <div class="landing-page-content">
        <h3 class="sub-section-content">
          Chaviti designs and curates experiences that celebrate life's
          richness. For a decade, we have scoured the globe — uncovering hidden
          gems, forging connections with local experts, savouring local
          cuisines, immersing ourselves in the local culture, and connecting
          with its people.
          <br /><br />
          Discover the world through our eyes, where every experience is a
          masterpiece waiting to be painted.
        </h3>
      </div>
      <div class="contact-us-section">
        <button mat-button>Contact Us</button>
      </div>
    </div>
  </div>
  <br />
  <div class="website-landing-page-mobile-discover-more">
    <div class="discover-more-mobile-image">
      <img
        src="/assets/screen3.jpg"
        alt="Image"
        class="discover-more-image-screen-mobile"
      />
    </div>
    <div class="discover-more-section-mobile-content">
      <div class="discover-more-landing-page-content">
        <h1 class="discover-more-main-section-content">
          Your story, your experience — unparalleled, unforgettable, and
          exclusively yours.
        </h1>
        <h3 class="sub-section-content">
          No two people are alike, and neither are their journeys. Through a
          deep understanding of your likes, dislikes, quirks, desires,
          motivations, and aspirations, we craft highly personalised experiences
          that reflect the essence of who you are.
        </h3>
      </div>
      <div class="discover-more-section">
        <button mat-button>Discover More</button>
      </div>
    </div>
  </div>
  <div class="heriloom-section-mobile-view">
    <div class="heriloom-section-mobile-view-heading">
      A Feeling, an heirloom,<br />
      a passion, a project, a surprise
    </div>
  </div>
</div>

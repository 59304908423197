import { Component, HostListener } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  registrationForm: FormGroup;
  screenWidth: number = window.innerWidth;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RegistrationComponent>,
    private service: ApiService,
    private router: Router
  ) {
    console.log('DialogRef:', this.dialogRef);
    this.registrationForm = this.fb.group(
      {
        username: [
          '',
          [
            Validators.required,
            Validators.pattern(/^CHAV\d{5}$/), // Ensures format CHAVxxxxx
          ],
        ],
        firstName: ['', [Validators.required, Validators.minLength(1)]],
        lastName: ['', [Validators.required, Validators.minLength(1)]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required]],
        acceptPrivacy: [false, [Validators.requiredTrue]], // Privacy checkbox
        receiveNews: [false], // Optional checkbox
      },
      { validators: this.passwordMatchValidator } // Attach the custom validator
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.screenWidth = (event.target as Window).innerWidth;
  }

  passwordMatchValidator(form: AbstractControl): ValidationErrors | null {
    const password = form.get('password')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordsMismatch: true };
  }

  onSubmit() {
    if (this.registrationForm.valid) {
      console.log(this.registrationForm.value);
      this.service.createUser(this.registrationForm.value).subscribe(
        (response) => {
          console.log('User Created Successfully:', response);
          this.closeModal();
          alert('User Created Successfully');
          this.closeModalAndNavigate();
        },
        (error) => {
          console.error('Error:', error);

          if (error.status === 400 && error.error && error.error.message) {
            alert(`Error: ${error.error.message}`);
          } else {
            alert('An unexpected error occurred. Please try again later.');
          }
        }
      );
    } else {
      console.error('Form is invalid');
      alert('Please fill out all required fields correctly.');
    }
  }

  get username() {
    return this.registrationForm.get('username');
  }

  get firstName() {
    return this.registrationForm.get('firstName');
  }

  get lastName() {
    return this.registrationForm.get('lastName');
  }

  get email() {
    return this.registrationForm.get('email');
  }

  get password() {
    return this.registrationForm.get('password');
  }

  get confirmPassword() {
    return this.registrationForm.get('confirmPassword');
  }

  closeModalAndNavigate(): void {
    this.dialogRef.close();
    this.router.navigate(['/login']);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}

<div [class]="defineClass" class="chaviti-header-container">
  <mat-toolbar color="primary" class="transparent">
    <mat-toolbar-row>
      <button
        mat-icon-button
        (click)="sidenav.toggle()"
        fxShow="true"
        fxHide.gt-sm
      >
        <mat-icon>menu</mat-icon>
      </button>
      <div fxShow="true" fxHide.lt-md class="row-level-navigation-information">
        <span class="company-name">Chaviti</span>
        <ng-container *ngFor="let eachVal of headerContent">
          <button
            mat-button
            [routerLink]="eachVal.route"
            routerLinkActive="active"
            (click)="
              eachVal.name === 'Register' ? openRegistrationModal() : null
            "
          >
            {{ eachVal.name }}
          </button>
        </ng-container>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav mode="side">
      <mat-nav-list>
        <a (click)="sidenav.toggle()" href="javascript:void(0)" mat-list-item>
          <mat-icon>close</mat-icon>
        </a>
        <ng-container *ngFor="let eachVal of headerContent">
          <a
            mat-list-item
            [routerLink]="eachVal.route"
            routerLinkActive="active"
            (click)="
              sidenav.toggle();
              eachVal.name === 'Register' ? openRegistrationModal() : null
            "
          >
            {{ eachVal.name }}
          </a>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill> </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<!-- 
<div class="mobile-view-header" *ngIf="isMobile">
  <mat-drawer-container>
    <mat-drawer #drawer animationDuration="10000">
      <div class="navigation-container">
        <div class="drawer-navigation-panel">
          <button
            mat-icon-button
            color="accent"
            aria-label="Close menu"
            (click)="drawer.toggle()"
          >
            <mat-icon>close</mat-icon>
          </button>
          <span class="company-name">Chaviti</span>
        </div>
        <div class="navigation-information">
          <div *ngFor="let eachVal of headerContent" class="each-header-item">
            <button mat-button routerLink="{{ eachVal.route }}">
              {{ eachVal.name }}
            </button>
          </div>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div class="header-section-mobile-view">
        <button
          mat-icon-button
          color="accent"
          aria-label="Open menu"
          (click)="drawer.toggle()"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <span class="company-name">Chaviti</span>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div> -->

import { Component, HostListener } from '@angular/core';
import { InviteModalComponent } from '../modals/invite-modal/invite-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tour-exp',
  templateUrl: './tour-exp.component.html',
  styleUrls: ['./tour-exp.component.scss'],
})
export class TourExpComponent {
  inviteCode: string = '';
  links = [
    { id: 'overview', name: 'Overview' },
    { id: 'itinerary', name: 'Itinerary' },
    { id: 'stays', name: 'Stays' },
    { id: 'dates-prices', name: 'Dates & Prices' },
    { id: 'journey-details', name: 'Journey Details' },
  ];
  showBackToTop = false;

  constructor(private dialog: MatDialog) {}

  scrollTo(section: string, event?: Event) {
    if (event) {
      event.preventDefault();
    }
    const element = document.getElementById(section);
    const headerHeight =
      document.querySelector('.header-container')?.clientHeight || 0;
    const navHeight = document.querySelector('.sticky-bar')?.clientHeight || 0;
    const totalOffset = headerHeight + navHeight;
    if (element) {
      window.scrollTo({
        top: element.offsetTop - totalOffset,
        behavior: 'smooth',
      });
    }
  }
  @HostListener('window:scroll', [])
  onScroll() {
    this.showBackToTop = window.scrollY > 200;
  }

  openModal() {
    this.dialog.open(InviteModalComponent, {
      width: '400px',
    });
  }
}

<div class="trip-container">
  <div class="left">
    <h2>Plan your trip for 2025</h2>
    <h3>INCLUSIONS</h3>
    <div fxLayout="column" fxLayoutGap="10px" class="benefits-list">
      <div *ngFor="let benefit of benefitsList" class="benefit-item">
        <mat-icon>check_circle</mat-icon>
        <span>{{ benefit }}</span>
      </div>
    </div>
  </div>
  <div class="right">
    <h2>DATES & PRICES</h2>
    <p>
      Prices are in USD, including internal air, per person, double occupancy.
    </p>
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let trip of trips"
        (opened)="closeOtherTrips(trip)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ trip.date }}
          </mat-panel-title>
          <mat-panel-description>
            <div class="status">{{ trip.status }}</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p><strong>Price:</strong> {{ trip.price }}</p>
        <p><strong>Single Supplement:</strong> {{ trip.supplement }}</p>
        <p>{{ trip.details }}</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormModulesComponent } from './form-modules/form-modules.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginComponent } from './login/login.component';
import { WebsiteLandingPageComponent } from './website-landing-page/website-landing-page.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { LayoutComponent } from './layout/layout.component';
import { TourComponent } from './pages/tour/tour.component';
import { TourExpComponent } from './pages/tour-exp/tour-exp.component';
import { SelectionPageComponent } from './pages/selection-page/selection-page.component';

const routes: Routes = [
  {
    path: 'admin', // Use a path that corresponds to your feature route
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  { path: 'form-gitam', component: FormModulesComponent },
  // { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'login', component: LandingPageComponent },

  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: WebsiteLandingPageComponent },
      { path: 'dashboard', component: TourComponent },
      {
        path: 'tour',
        component: TourExpComponent,
      },
      { path: 'join', component: SelectionPageComponent },
      // Add additional routes here
    ],
  },
  // { path: '', component: WebsiteLandingPageComponent },
  { path: 'register', component: RegistrationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationComponent } from '../pages/registration/registration.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isMobile: boolean = false;

  headerContent = [
    { name: 'Our Story', route: 'discover' },
    { name: 'Our Approach', route: 'approach' },
    { name: 'Concepts', route: 'concept' },
    { name: 'Join Us', route: 'dashboard' },
    { name: 'Tour', route: 'tour' },
    { name: 'Contact Us', route: 'home' },
    { name: 'Register', route: 'home' }, // Updated route
  ];

  defineClass = '';

  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkIsMobile();
  }

  openRegistrationModal(): void {
    this.dialog.open(RegistrationComponent, {
      width: '80vw',
      height: '100vh',
      panelClass: 'custom-modal-container',
    });
  }

  openHeader(sideNav: any) {
    const stateOfSideNavigation = sideNav._animationState;
    console.log('show value', sideNav, stateOfSideNavigation);
    if (stateOfSideNavigation === 'open') {
      this.defineClass = '';
    } else {
      this.defineClass = 'chaviti-header-container-info';
    }
    sideNav.toggle();
  }

  checkIsMobile() {
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }
}

<section id="journey-overview">
  <div class="overview-container">
    <div class="overview-left">
      <p class="overview-title">Overview</p>
      <h4 class="overview-description">
        Similar in many ways yet distinctive in others, Spain and Portugal
        combine to offer travel experiences rich in history and an air of
        romance. Explore them both on one spectacular journey, witnessing daily
        life today and discovering how a range of peoples influenced the
        culture, cuisine and arts across the entire Iberian peninsula.
      </h4>

      <!-- Feature Items (3 Left, 2 Right) -->
      <div class="features-container">
        <div class="feature-item feature-left">
          <img
            class="feature-icon"
            src="assets/icons/feature-icon.svg"
            alt="Feature Icon"
          />
          <p>
            Experience two Gehry-designed architectural masterpieces, exploring
            the Guggenheim Museum Bilbao and staying for two nights at the Hotel
            Marqués de Riscal
          </p>
        </div>
        <div class="feature-item feature-left">
          <img
            class="feature-icon"
            src="assets/icons/feature-icon.svg"
            alt="Feature Icon"
          />
          <p>
            At a palace in Seville, savor a private dinner and a superb flamenco
            performance, on an A&K-exclusive experience
          </p>
        </div>
        <div class="feature-item feature-left">
          <img
            class="feature-icon"
            src="assets/icons/feature-icon.svg"
            alt="Feature Icon"
          />
          <p>
            Roam the medieval streets of Córdoba’s Jewish Quarter and view the
            stunning interior of the city’s Grand Mosque
          </p>
        </div>
        <div class="feature-item feature-right">
          <img
            class="feature-icon"
            src="assets/icons/feature-icon.svg"
            alt="Feature Icon"
          />
          <p>
            Taste a variety of culinary treats on a tapas adventure in Madrid,
            partaking in a delectable Spanish cultural tradition
          </p>
        </div>
        <div class="feature-item feature-right">
          <img
            class="feature-icon"
            src="assets/icons/feature-icon.svg"
            alt="Feature Icon"
          />
          <p>
            Learn the secrets to baking a Portuguese dessert specialty, and then
            indulge in your creation, on a private, hands-on pastry-making
            workshop in Lisbon
          </p>
        </div>
      </div>

      <!-- PDF Download Button -->
      <div class="pdf-button-container">
        <button class="pdf-download-button">Download PDF</button>
      </div>
    </div>

    <!-- Overview Image (Right side) -->
    <div class="overview-right">
      <img
        class="overview-img"
        src="assets/images/overview-img.webp"
        alt="Overview Image"
      />
    </div>
  </div>
</section>

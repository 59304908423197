<!-- <div class="container">
    <img class="background-image" src="assets/background-image.jpg">
    <div class="centered" *ngIf="!hasResponseSubmitted">
        <h1>Chaviti is down for modernisation!</h1>
        <div class="notify-section">
            <form class="email-form">
                <mat-form-field class="content-full-width">
                    <mat-label>Enter Name Here</mat-label>
                    <input type="text" matInput [formControl]="userFormControl">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Enter Phone Number </mat-label>
                    <input type="text" matInput [formControl]="phoneNumberFromControl">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Enter Email here</mat-label>
                    <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" placeholder="Ex. pat@example.com">
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
            </form>
            <button class="notify-button" mat-raised-button color="accent" (click)="saveText()">Notify Me!</button>
        </div>
    </div>
    <div class="centered response-on-success" *ngIf="hasResponseSubmitted">
        <div class="text-on-submission">
            Thank You for Registering
        </div>
    </div>
</div> -->

<!-- Temporary for phone Pe-->

<div class="container">
  <h1 class="company-name">Chaviti</h1>

  <div class="centered" *ngIf="!hasResponseSubmitted">
    <div class="notify-section">
      <form class="email-form">
        <mat-form-field class="content-full-width">
          <mat-label>Enter User Name</mat-label>
          <input type="text" matInput [formControl]="userFormControl" />
        </mat-form-field>
        <mat-form-field class="content-full-width">
          <mat-label>Enter Password* </mat-label>
          <input
            type="password"
            matInput
            [formControl]="phoneNumberFromControl"
          />
        </mat-form-field>
      </form>
      <button class="notify-button" mat-raised-button (click)="loginUser()">
        Login
      </button>
      <div class="register-link">
        <span>New User? </span>
        <span (click)="openRegistrationModal()" class="register-link-text">
          Register Now
        </span>
      </div>
    </div>
  </div>
  <div class="centered response-on-success" *ngIf="hasResponseSubmitted">
    <div class="text-on-submission">Thank You for Registering</div>
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-more-adventures',
  templateUrl: './more-adventures.component.html',
  styleUrls: ['./more-adventures.component.scss'],
})
export class MoreAdventuresComponent {
  selectedOption: string = 'option1';

  options = [
    { value: 'option1', label: 'Europe Adventures1' },
    { value: 'option2', label: 'Culture & History Adventures' },
    { value: 'option3', label: 'City Adventures' },
    { value: 'option4', label: 'Solo Adventures' },
  ];
  customOptions = {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 4,
      },
    },
  };

  carouselImages1 = [
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description: '14 days • limited to 18 guests',
      price: '$19,995',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'A Thrilling Adventure in the Kenyan Wilderness',
      description: '8 days • limited to 18 guests',
      price: '$10,795',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/eb9e14a36021e7cab8cae231466a03750a78d41d-3612x3612.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Explore Antarctica, South Georgia and the Falkland Islands',
      description: '10 days • limited to 18 guests',
      price: '$16,895',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Discover the Beauty of Iceland',
      description: '10 days • limited to 18 guests',
      price: '$10,995',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Experience the Wonders of Australia',
      description: '11 days • limited to 24 guests',
      price: '$19,895',
    },
  ];
  carouselImages2 = [
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description: '11 days • limited to 24 guests',
      price: '$19,895',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description: '14 days • limited to 18 guests',
      price: '$19,995',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'A Thrilling Adventure in the Kenyan Wilderness',
      description: '8 days • limited to 18 guests',
      price: '$10,795',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/eb9e14a36021e7cab8cae231466a03750a78d41d-3612x3612.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Explore Antarctica, South Georgia and the Falkland Islands',
      description: '10 days • limited to 18 guests',
      price: '$16,895',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Discover the Beauty of Iceland',
      description: '10 days • limited to 18 guests',
      price: '$10,995',
    },
  ];
  carouselImages3 = [
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description: '8 days • limited to 18 guests',
      price: '$10,795',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description: '14 days • limited to 18 guests',
      price: '$19,995',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/eb9e14a36021e7cab8cae231466a03750a78d41d-3612x3612.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Explore Antarctica, South Georgia and the Falkland Islands',
      description: '10 days • limited to 18 guests',
      price: '$16,895',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Discover the Beauty of Iceland',
      description: '10 days • limited to 18 guests',
      price: '$10,995',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Experience the Wonders of Australia',
      description: '11 days • limited to 24 guests',
      price: '$19,895',
    },
  ];
  carouselImages4 = [
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description: '10 days • limited to 18 guests',
      price: '$16,895',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Uncover the Secrets of India',
      description: '14 days • limited to 18 guests',
      price: '$19,995',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'A Thrilling Adventure in the Kenyan Wilderness',
      description: '8 days • limited to 18 guests',
      price: '$10,795',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/37148a848313b664c1b39d08a5785c762afdcb1f-1646x1646.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Discover the Beauty of Iceland',
      description: '10 days • limited to 18 guests',
      price: '$10,995',
    },
    {
      image:
        'https://cdn.abercrombiekent.com/images/bsiop5ln/production/be8a4b19dd2742c4c152456839d2d1ec391b32fa-3000x3000.jpg?w=3840&q=75&fit=max&auto=format',
      title: 'Experience the Wonders of Australia',
      description: '11 days • limited to 24 guests',
      price: '$19,895',
    },
  ];
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { ContactService } from './contact.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormModulesComponent } from './form-modules/form-modules.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AdminModule } from './admin/admin.module';
import { LoginComponent } from './login/login.component';
import { WebsiteLandingPageComponent } from './website-landing-page/website-landing-page.component';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RegistrationComponent } from './pages/registration/registration.component';

import { LayoutComponent } from './layout/layout.component';
import { TourComponent } from './pages/tour/tour.component';
import { WebsiteLandingPageMobileComponent } from './website-landing-page-mobile/website-landing-page-mobile.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TourExpComponent } from './pages/tour-exp/tour-exp.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { WhereToStayComponent } from './pages/where-to-stay/where-to-stay.component';
import { MoreAdventuresComponent } from './pages/more-adventures/more-adventures.component';
import { ItineraryComponent } from './pages/itinerary/itinerary.component';
import { DatePriceComponent } from './pages/date-price/date-price.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { InviteModalComponent } from './pages/modals/invite-modal/invite-modal.component';
import { SelectionPageComponent } from './pages/selection-page/selection-page.component';

@NgModule({
  declarations: [
    AppComponent,
    FormModulesComponent,
    LandingPageComponent,
    LoginComponent,
    WebsiteLandingPageComponent,
    HeaderComponent,
    RegistrationComponent,
    LayoutComponent,
    TourComponent,
    WebsiteLandingPageMobileComponent,
    TourExpComponent,
    OverviewComponent,
    WhereToStayComponent,
    MoreAdventuresComponent,
    ItineraryComponent,
    DatePriceComponent,
    InviteModalComponent,
    SelectionPageComponent,
  ],
  imports: [
    AdminModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,

    MatCheckboxModule,
    MatGridListModule,
    MatRadioModule,
    CarouselModule,
    MatExpansionModule,
  ],
  providers: [
    ContactService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

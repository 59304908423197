import { Component, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  headerClass = '';
  isMobile: boolean = false;
  routePath: string = '/';
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.routePath = event.urlAfterRedirects;
        console.log(this.routePath);
      });
    this.checkIsMobile();
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event: any) {
  //   this.headerClass = '';
  //   const scrollPosition =
  //     window.pageYOffset || document.documentElement.scrollTop;
  //   if (scrollPosition > 200) {
  //     this.headerClass = !this.isMobile ? 'change-header-color' : '';
  //   }
  // }

  checkIsMobile() {
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }
}

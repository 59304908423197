<h2 mat-dialog-title>Enter the Invite Code</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Invite Code</mat-label>
    <input matInput [(ngModel)]="inviteCode" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-flat-button class="submit-button" (click)="submitCode()">
    Submit
  </button>
</mat-dialog-actions>

import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-polylinedecorator';

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
})
export class ItineraryComponent {
  @ViewChild('itineraryContent', { static: false })
  itineraryContent!: ElementRef;

  map!: L.Map;
  markerLayer!: L.LayerGroup;
  itineraryData = [
    {
      name: 'Lisbon',
      lat: 38.7169,
      lng: -9.1399,
      details: [
        {
          day: 'Day 1 | Arrive Lisbon, Portugal',
          description:
            'Arrive in Lisbon, capital of Portugal, where you are met and transferred to your hotel. Settle in and then join your A&K Resident Tour Director for a welcome drink, followed by dinner at your hotel.',
          hotel: 'Olissippo Lapa Palace',
          meals: 'Dinner',
        },
        {
          day: 'Day 2 | Lisbon - Private Pastry-Making Workshop',
          description:
            'This morning, set out for a panoramic city tour, including Jerónimos Monastery. Afterward, try your hand at creating the famous Portuguese specialty, pastel de nata (custard tart), in a private workshop.',
          hotel: 'Olissippo Lapa Palace',
          meals: 'Breakfast',
        },
      ],
    },
    {
      name: 'Evora',
      lat: 38.5711,
      lng: -7.9135,
      details: [
        {
          day: 'Day 3 | Évora - Walled Medieval City',
          description:
            'Journey to Évora, a beautifully preserved medieval city, stopping for a wine tasting en route. Visit the historic town center, a UNESCO World Heritage Site.',
          hotel: 'Convento do Espinheiro',
          meals: 'Breakfast, Lunch, Dinner',
        },
      ],
    },
    {
      name: 'Seville',
      lat: 37.3886,
      lng: -5.9823,
      details: [
        {
          day: 'Day 4 | Mérida, Spain & Seville - Ancient Mérida',
          description:
            "Drive eastward into Spain, stopping in Mérida to view its Roman amphitheater and ancient aqueduct before continuing into Spain's Andalusia region for a two-night stay in Seville.",
          hotel: 'Hotel Alfonso XIII',
          meals: 'Breakfast, Dinner',
        },
        {
          day: 'Day 5 | Seville - Gothic Cathedral & Jewish Quarter',
          description:
            "Explore Seville, including a visit to the world's largest Gothic cathedral. Walk through the Jewish Quarter, with its labyrinth of narrow streets dating to Moorish rule.",
          hotel: 'Hotel Alfonso XIII',
          meals: 'Breakfast',
        },
      ],
    },
    {
      name: 'Granada',
      lat: 37.1773,
      lng: -3.5986,
      details: [
        {
          day: 'Day 6 | Granada - The Moorish Architecture of Alhambra',
          description:
            'Travel to Granada and visit the Alhambra, a vast palace and UNESCO World Heritage Site that exemplifies Moorish architecture. Tonight, dine at a local restaurant overlooking the site.',
          hotel: 'Hotel Palacio de Santa Paula',
          meals: 'Breakfast, Lunch, Dinner',
        },
      ],
    },
    {
      name: 'Madrid',
      lat: 40.4168,
      lng: -3.7038,
      details: [
        {
          day: 'Day 7 | Córdoba & Madrid - Jewish Quarter & Mezquita',
          description:
            'Visit Córdoba’s historic Jewish neighborhood and the Mezquita, a former Great Mosque. Later, board a high-speed train to Madrid and enjoy a tapas tour for dinner.',
          hotel: 'Palacio de los Duques Gran Melia',
          meals: 'Breakfast, Dinner',
        },
        {
          day: 'Day 8 | Madrid - Royal Splendor',
          description:
            'Tour Madrid, including the Royal Palace with its opulent decor. Enjoy lunch at a local restaurant and an afternoon at leisure.',
          hotel: 'Palacio de los Duques Gran Melia',
          meals: 'Breakfast, Lunch',
        },
        {
          day: 'Day 9 | Madrid & Toledo - Design Your Day',
          description:
            'Choose from activities: a bike tour of Madrid, a visit to medieval Toledo, or a guided tour of the Prado Museum. Spend the evening at leisure.',
          hotel: 'Palacio de los Duques Gran Melia',
          meals: 'Breakfast',
        },
      ],
    },
    {
      name: 'Elciego',
      lat: 42.5116,
      lng: -2.6179,
      details: [
        {
          day: 'Day 10 | Elciego - An Enchanting Food & Wine Experience',
          description:
            'Journey to La Rioja for a gourmet lunch with wine pairings at the Vivanco Museum of Wine Culture. Proceed to Elciego and check into the Hotel Marqués de Riscal.',
          hotel: 'Hotel Marqués de Riscal',
          meals: 'Breakfast, Lunch',
        },
        {
          day: 'Day 11 | Elciego - Tasting the Fruits of the Rioja',
          description:
            'Explore the Rioja region, visit an olive oil mill, walk through Laguardia, and taste wine with tapas in one of the village cellars.',
          hotel: 'Hotel Marqués de Riscal',
          meals: 'Breakfast, Lunch, Dinner',
        },
      ],
    },
    {
      name: 'Barcelona',
      lat: 41.3874,
      lng: 2.1686,
      details: [
        {
          day: 'Day 12 | Bilbao - Guggenheim Museum Tour',
          description:
            'Drive to Bilbao to tour the Guggenheim Museum, featuring modern and contemporary art. After lunch, fly to Barcelona for a light dinner.',
          hotel: 'Almanac Barcelona',
          meals: 'Breakfast, Lunch, Dinner',
        },
        {
          day: 'Day 13 | Barcelona - Gaudí’s Unfinished Masterpiece',
          description:
            'Tour Barcelona, including the Barcelona Pavilion and the Sagrada Família. Spend the afternoon exploring the city before a farewell dinner.',
          hotel: 'Almanac Barcelona',
          meals: 'Breakfast, Dinner',
        },
        {
          day: 'Day 14 | Depart Barcelona',
          description:
            'After breakfast, transfer to the airport for your departing flight.',
          hotel: 'N/A',
          meals: 'Breakfast',
        },
      ],
    },
  ];

  activeCity: string = 'Lisbon';

  ngAfterViewInit(): void {
    this.map = L.map('map').setView([38.7169, -9.1399], 6); // Initial coordinates (Lisbon)

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    }).addTo(this.map);

    this.addMarkers();
    this.drawPolyline();
    this.updateMapMarker(this.activeCity); // Make sure the marker is set to the active city initially
  }

  addMarkers() {
    this.markerLayer = L.layerGroup().addTo(this.map);
    this.itineraryData.forEach((city: any) => {
      const customIcon = L.icon({
        iconUrl: 'assets/icons/location.svg', // Use any CDN marker
        iconSize: [25, 41], // Adjust size
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl:
          'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [41, 41],
      });

      L.marker([city.lat, city.lng], { icon: customIcon })
        .bindPopup(`<b>${city.name}</b>`)
        .addTo(this.markerLayer);
    });
  }

  drawPolyline() {
    const coordinates: any = this.itineraryData.map((city) => [
      city.lat,
      city.lng,
    ]);

    L.polyline(coordinates, {
      color: 'black',
      weight: 4,
      opacity: 0.7,
      dashArray: '5, 10',
    }).addTo(this.map);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    event.preventDefault();
    const sections = document.querySelectorAll('.itinerary-section');
    let scrollPosition = (event.target as HTMLElement).scrollTop + 200; // Adjust offset based on the scrollable content

    sections.forEach((section: any) => {
      const top = section.offsetTop;
      const height = section.offsetHeight;
      if (scrollPosition >= top && scrollPosition < top + height) {
        this.activeCity = section.id;
        this.updateMapMarker(this.activeCity);
      }
    });
  }
  updateMapMarker(cityName: string) {
    const city = this.itineraryData.find((c) => c.name === cityName);
    if (city) {
      this.map.flyTo([city.lat, city.lng], 6, {
        animate: true,
        duration: 1.5,
      });
    }
  }
}

import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MyErrorStateMatcher } from '../app.component';
import { ContactService } from '../contact.service';
import { RegistrationComponent } from '../pages/registration/registration.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  title = 'chaviti-website';
  pattern = /06([0-9]{8})/;
  emailFormControl = new FormControl('', [Validators.email]);
  matcher = new MyErrorStateMatcher();
  userFormControl = new FormControl('', [Validators.required]);
  phoneNumberFromControl = new FormControl('', [
    Validators.maxLength(10),
    Validators.minLength(10),
  ]);
  hasResponseSubmitted = false;

  constructor(
    public dialog: MatDialog,
    private contactService: ContactService,
    private router: Router
  ) {}

  saveText() {
    this.hasResponseSubmitted = false;
    const emailValue = JSON.stringify(this.emailFormControl.value);
    const payload = {
      name: JSON.stringify(this.userFormControl.value),
      email: emailValue,
      phone_number: JSON.stringify(this.phoneNumberFromControl.value),
    };
    console.log('informaiton', payload);

    this.contactService.registerUser(payload).subscribe({
      next: (response: any) => {
        this.hasResponseSubmitted = true;
      },
      error: (err) => {
        this.hasResponseSubmitted = false;
      },
    });
  }

  openRegistrationModal(): void {
    this.dialog.open(RegistrationComponent, {
      width: '80vw',
      height: '100vh',
      panelClass: 'custom-modal-container',
    });
  }

  loginUser() {
    const payload = {
      username: this.userFormControl.value,
      password: this.phoneNumberFromControl.value,
    };

    this.contactService.validateUser(payload).subscribe({
      next: (response: any) => {
        console.log('Login successful');
        this.router.navigate(['/']);
      },
      error: (err) => {
        console.error('Login failed:', err);
        this.hasResponseSubmitted = false;

        // Show an alert with the error message from the response
        if (err.error && err.error.message) {
          alert(`Error: ${err.error.message}`);
        } else {
          alert('An unexpected error occurred. Please try again later.');
        }
      },
    });
  }
}

import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss'],
})
export class InviteModalComponent {
  inviteCode: string = '';

  constructor(
    public dialogRef: MatDialogRef<InviteModalComponent>,
    private router: Router,
    private service: ApiService
  ) {}

  submitCode() {
    console.log('Entered Invite Code:', this.inviteCode);
    this.service.validateInviteCode({ inviteCode: this.inviteCode }).subscribe({
      next: (data) => {
        if (data) {
          this.dialogRef.close();
          alert('Code Verified successfully');
          this.router.navigate(['join']);
        }
      },
      error: (err) => {
        console.error(err);
        alert(err?.error?.message);
        this.inviteCode = '';
      },
    });
  }
}

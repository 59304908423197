<div class="modal-container">
  <!-- Modal Image -->
  <div class="modal-image" *ngIf="screenWidth > 1024">
    <img src="assets/images/reg_img.webp" alt="img" />
  </div>

  <!-- Modal Form -->
  <div class="modal-form">
    <div class="modal-header">
      <h2>Create a Guest Account</h2>
      <button mat-icon-button class="close-icon" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <p>Are you a Travel Advisor? <a href="#">SIGN UP IN HERE</a></p>
    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="form-field" appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" />
        <mat-error *ngIf="username?.hasError('required')">
          Username is required
        </mat-error>
        <mat-error *ngIf="username?.hasError('pattern')">
          Username must follow the format CHAVxxxxx (e.g., CHAV12345)
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field" appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error *ngIf="firstName?.hasError('required')">
          First name is required
        </mat-error>
        <mat-error *ngIf="firstName?.hasError('minlength')">
          First name is too short
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field" appearance="fill">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
        <mat-error *ngIf="lastName?.hasError('required')">
          Last name is required
        </mat-error>
        <mat-error *ngIf="lastName?.hasError('minlength')">
          Last name is too short
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field" appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" />
        <mat-error *ngIf="email?.hasError('required')">
          Email is required
        </mat-error>
        <mat-error *ngIf="email?.hasError('email')">
          Invalid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field" appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" />
        <mat-error *ngIf="password?.hasError('required')">
          Password is required
        </mat-error>
        <mat-error *ngIf="password?.hasError('minlength')">
          Password must be at least 6 characters
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field" appearance="fill">
        <mat-label>Confirm Password</mat-label>
        <input matInput type="password" formControlName="confirmPassword" />
        <mat-error *ngIf="confirmPassword?.hasError('required')">
          Confirm Password is required
        </mat-error>
        <mat-error *ngIf="registrationForm.hasError('passwordsMismatch')">
          Passwords do not match
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="acceptPrivacy">
        I accept the <a href="#">Privacy Policy</a>
      </mat-checkbox>
      <mat-checkbox formControlName="receiveNews">
        Yes! I would like to receive news and other information.
      </mat-checkbox>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="registrationForm.invalid"
      >
        SIGN UP
      </button>
    </form>

    <p>
      Already have an account? <a (click)="closeModalAndNavigate()">LOG IN</a>
    </p>
  </div>
</div>

<div class="app-layout">
  <header class="header sticky" [class]="headerClass">
    <app-header></app-header>
  </header>

  <div
    class="landing-content-block-mobile-view"
    *ngIf="isMobile && routePath === '/'"
  >
    <app-website-landing-page-mobile></app-website-landing-page-mobile>
  </div>

  <main class="main-content" *ngIf="!(isMobile && routePath === '/')">
    <router-outlet></router-outlet>
  </main>
  <footer class="footer bg-black text-white">
    <div class="footer-container mat-grid-list">
      <mat-grid-list cols="2">
        <mat-grid-tile>
          <div
            fxFlex="1 1 100%"
            fxLayout="column"
            fxLayoutAlign="start start"
            style="height: auto; overflow: visible"
          >
            <div class="newsletter">
              <h3>Find your next adventure</h3>
              <p>
                A&K's newsletter is packed with inspiration for your next trip.
              </p>
              <form fxLayout="column" fxLayoutGap="20px">
                <mat-form-field appearance="fill">
                  <mat-label>First Name</mat-label>
                  <input matInput type="text" required />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Last Name</mat-label>
                  <input matInput type="text" required />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Email Address</mat-label>
                  <input matInput type="email" required />
                </mat-form-field>

                <label>Are you a travel advisor?</label>
                <mat-radio-group>
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>

                <p>
                  By entering your email, you agree to our
                  <a href="#">Terms of Use</a> and
                  <a href="#">Privacy Policy</a>, including receipt of emails
                  and promotions.
                </p>

                <button mat-raised-button color="primary">Subscribe</button>
              </form>
            </div>
          </div></mat-grid-tile
        >
        <mat-grid-tile>
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="20px">
            <!-- Second item: Company Links (20% width) -->
            <div
              fxFlex="1 1 30%"
              fxLayout="column"
              fxLayoutAlign="start start"
              style="height: auto"
            >
              <h3>Company</h3>
              <ul class="footer-links" fxLayout="column" fxLayoutGap="5px">
                <li><a href="#">About Us</a></li>
                <li><a href="#">A&K Philanthropy</a></li>
                <li><a href="#">Press</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>

            <!-- Third item: Services Links (20% width) -->
            <div
              fxFlex="1 1 30%"
              fxLayout="column"
              fxLayoutAlign="start start"
              style="height: auto"
            >
              <h3>Services</h3>
              <ul class="footer-links" fxLayout="column" fxLayoutGap="5px">
                <li><a href="#">Travel Brochures</a></li>
                <li><a href="#">A&K App</a></li>
                <li><a href="#">Trade Hub</a></li>
                <li><a href="#">A&K DMC Hub</a></li>
              </ul>
            </div>

            <!-- Fourth item: Policies and Legal Links (20% width) -->
            <div
              fxFlex="1 1 30%"
              fxLayout="column"
              fxLayoutAlign="start start"
              style="height: auto"
            >
              <h3>Policies and Legal</h3>
              <ul class="footer-links" fxLayout="column" fxLayoutGap="5px">
                <li><a href="#">Booking Terms and Conditions</a></li>
                <li><a href="#">Terms of Use</a></li>
                <li><a href="#">Cookie Preferences</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Data Processing Agreement</a></li>
                <li><a href="#">Modern Slavery Act</a></li>
                <li><a href="#">Email Opt-out</a></li>
              </ul>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="social-links">
        <a href="#" aria-label="Instagram"><mat-icon>photo_camera</mat-icon></a>
        <a href="#" aria-label="Facebook"><mat-icon>thumb_up</mat-icon></a>
        <a href="#" aria-label="YouTube"><mat-icon>videocam</mat-icon></a>
        <a href="#" aria-label="Pinterest"><mat-icon>bookmark</mat-icon></a>
        <a href="#" aria-label="LinkedIn"><mat-icon>business</mat-icon></a>
      </div>
    </div>
  </footer>
</div>

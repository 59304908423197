<section class="journey-intro">
  <img
    src="assets/images/reserve-bg.jpg"
    alt="japan-bg"
    class="journey-image"
  />
  <div class="image-overlay">
    <h2>WELCOME TO EXPO 2025 OSAKA</h2>
  </div>
</section>
<section>
  <div class="party-line-container">
    <div class="party-title">Join With A Party of</div>
    <div class="party-line-wrapper">
      <div class="party-line"></div>

      <span class="party-label adults">Adults</span>
      <div class="button-group adults-buttons">
        <button
          mat-raised-button
          color="primary"
          *ngFor="let num of [1, 2, 3, 4, 5, 6]"
          (click)="selectAdults(num)"
          [class.active]="selectedAdults === num"
        >
          {{ num }}
        </button>
      </div>

      <span class="party-label minors">Minors</span>
      <div class="button-group minors-buttons">
        <button
          mat-raised-button
          color="accent"
          *ngFor="let num of [1, 2, 3, 4, 5, 6]"
          (click)="selectMinors(num)"
          [class.active]="selectedMinors === num"
        >
          {{ num }}
        </button>
      </div>
    </div>
  </div>

  <div class="reserve-button-container">
    <button mat-flat-button class="reserve-button" (click)="reserve()">
      Reserve
    </button>
  </div>
</section>
